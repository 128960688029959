import React, { useEffect } from 'react';
import { reset } from './helpers';
import { logEvent } from '../utils/analytics';

export const Reset = () => {
  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        reset();
        logEvent('RESET', {
          event_category: 'PACE_CALCULATOR',
          event_label: 'KEYBOARD_ESC',
        });
      }
    });
  }, []);
  return (
    <div>
      <button
        type="button"
        id="pace-calculator-reset"
        style={{ marginTop: 10 }}
        onClick={() => {
          reset();
          logEvent('RESET', {
            event_category: 'PACE_CALCULATOR',
            event_label: 'MANUAL',
          });
        }}
      >
        Reset (esc)
      </button>
    </div>
  );
};
