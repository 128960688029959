import React from 'react';
import { generateQuery, getShareText } from './helpers/query';
import { logEvent } from '../utils/analytics';
import { copyToClipBoard } from './helpers/copy-to-clipboard';

export const Share = () => {

  const share = (query) => {
    if (navigator.share) {
      navigator.share({
        title: 'Pace Calculator',
        text: getShareText(),
        url: query,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }
  
  return (
    <div>
      <button
        type="button"
        style={{ marginTop: 10, marginLeft: 20 }}
        onClick={() => {
          const query = generateQuery();
          if (navigator.share) {
            share(query);
          } else {
            copyToClipBoard(query);
            alert(`Link copied! ${getShareText()}`);
          }
          logEvent('GENERATE_LINK', {
            event_category: 'PACE_CALCULATOR',
          });
        }}
      >
        Share
      </button>
    </div>
  );
};
