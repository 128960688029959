import React from 'react';
import { setDistanceFromEvent } from './helpers/set-get';
import { calculateDistance, report } from './helpers';
import { logEvent } from '../utils/analytics';

export const Distance = () => (
  <div className="entry-row">
    <div className="entry-unit">Distance</div>
    <div className="entry-fields background-secondary-contrast">
      <div className="multiple-input-container">
        <input name="distance" type="number" inputMode="decimal" />
        <select name="distance-unit" defaultValue="MILE" style={{ height: 28 }}>
          <option value="MILE">Miles</option>
          <option value="KILOMETER">Kilometers</option>
          <option value="METER">Meters</option>
          <option value="YARD">Yards</option>
        </select>
      </div>
      <div style={{ margin: '5px 0', fontSize: 12 }}>or</div>
      <select
        name="event"
        id="select-distance"
        style={{ height: 28 }}
        onChange={(event) => {
          setDistanceFromEvent();
          logEvent('SET_PRESET_DISTANCE', {
            event_category: 'PACE_CALCULATOR',
            event_label: event.target.value,
          });
        }}
      >
        <option value="false">Pick Event</option>
        <option value="5K">5K</option>
        <option value="8K">8K</option>
        <option value="10K">10K</option>
        <option value="Half-Marathon">Half-Marathon</option>
        <option value="Marathon">Marathon</option>
      </select>
    </div>
    <div className="entry-action background-secondary-contrast">
      <button
        type="button"
        id="button-calculate-distance"
        style={{ fontSize: 12 }}
        onClick={() => {
          calculateDistance();
          logEvent('CALCULATE_DISTANCE', {
            event_category: 'PACE_CALCULATOR',
            event_label: report(),
          });
        }}
      >
        Calculate Distance
      </button>
    </div>
  </div>
);
