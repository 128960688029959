import {
  setTimeHour,
  setTimeMinute,
  setTimeSecond,
  setPaceUnit,
  setDistance,
  setDistanceUnit,
  setPaceHour,
  setPaceMinute,
  setPaceSecond,
  getDistance,
  getDistanceUnit,
  getPaceUnit,
  getPace,
  getTime,
} from './set-get';
import {
  getHourFromSeconds,
  getMinuteFromSeconds,
  getRemainderSeconds,
} from './conversion';
import { MILE, METER, KILOMETER, YARD } from '../constants/common';
import { FORM } from './';

export const setQuery = (t, d, dunit, p, punit) => {
  const params = new URLSearchParams();
  if (t) params.set('t', t);
  if (d) params.set('d', d);
  if (dunit) params.set('dunit', dunit);
  if (p) params.set('p', p);
  if (punit) params.set('punit', punit);
  window.history.pushState(
    null,
    '',
    window.location.pathname + '?' + params.toString()
  );
};

export const setParametersFromQuery = () => {
  const params = new URLSearchParams(document.location.search.substring(1));
  const time = params.get('t');
  if (!!Number(time) && Number(time > 0)) {
    setTimeHour(getHourFromSeconds(time));
    setTimeMinute(getMinuteFromSeconds(time));
    setTimeSecond(getRemainderSeconds(time));
  }
  const distance = params.get('d');
  if (!!Number(distance) && Number(distance > 0)) {
    setDistance(distance);
  }
  const dunit = params.get('dunit');
  if ([MILE, KILOMETER, METER, YARD].includes(dunit)) {
    setDistanceUnit(dunit);
  }
  const pace = params.get('p');
  if (!!Number(pace) && Number(pace > 0)) {
    setPaceHour(getHourFromSeconds(pace));
    setPaceMinute(getMinuteFromSeconds(pace));
    setPaceSecond(getRemainderSeconds(pace));
  }
  const punit = params.get('punit');
  if (
    [
      MILE,
      KILOMETER,
      METER,
      YARD,
      'Half Mile',
      'Quarter Mile',
      'Eigth Mile',
      '1500M',
      '800M',
      '400M',
      '200M',
    ].includes(dunit)
  ) {
    setPaceUnit(punit);
  }
};

export const generateQuery = () => {
  setQuery(
    getTime(),
    getDistance(),
    getDistanceUnit(),
    getPace(),
    getPaceUnit()
  );
  return window.location.href;
};

export const getShareText = () => {

  const timeHour = FORM()['time-hour'].value;
  const timeMin = FORM()['time-minute'].value;
  const timeSecond = FORM()['time-second'].value || '00';

  const paceHour = FORM()['pace-hour'].value;
  const paceMin = FORM()['pace-minute'].value;
  const paceSecond =FORM()['pace-second'].value || '00';

  return `${getDistance()} ${getDistanceUnit()} in ${parseInt(timeHour) > 0 ? `${parseInt(timeHour, 10)}:` : ''}${parseInt(timeMin) > 0 ? `${parseInt(timeMin, 10)}:` : ''}${timeSecond} (${parseInt(paceHour) > 0 ? `${parseInt(paceHour, 10)}:` : ''}${parseInt(paceMin) > 0 ? `${parseInt(paceMin, 10)}:` : ''}${paceSecond}/${getPaceUnit()})`
}

export const resetQuery = () => {
  window.history.pushState(null, '', window.location.pathname);
};
