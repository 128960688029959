import { FORM } from './';
import { getSeconds } from './conversion';
import {
  MILE,
  KILOMETER,
  HALF_MARATHON_DISTANCE,
  MARATHON_DISTANCE,
} from '../constants/common';

export const setDistance = (distance) => (FORM()['distance'].value = distance);
export const setDistanceUnit = (unit) => {
  FORM()['distance-unit'].options[unit === MILE ? 0 : 1].selected = true;
};
export const getDistance = () => FORM()['distance'].value;
export const getDistanceUnit = () =>
  FORM()['distance-unit'].options[FORM()['distance-unit'].options.selectedIndex]
    .value;

export const setPaceHour = (hour) => (FORM()['pace-hour'].value = hour);
export const setPaceMinute = (minute) => (FORM()['pace-minute'].value = minute);
export const setPaceSecond = (second) => (FORM()['pace-second'].value = second);
export const setPaceUnit = (unit) => {
  const options = FORM()['pace-unit'].options;
  for (let i = 0; i < options.length; i++) {
    if (options[i].value === unit) {
      FORM()['pace-unit'].options[i].selected = true;
    }
  }
};
export const getPaceUnit = () =>
  FORM()['pace-unit'].options[FORM()['pace-unit'].options.selectedIndex].value;
export const getPace = () => {
  const paceHour = Number(FORM()['pace-hour'].value);
  const paceMin = Number(FORM()['pace-minute'].value);
  const paceSecond = Number(FORM()['pace-second'].value);
  return getSeconds(paceHour, paceMin, paceSecond);
};

export const setTimeHour = (hour) => (FORM()['time-hour'].value = hour);
export const setTimeMinute = (minute) => (FORM()['time-minute'].value = minute);
export const setTimeSecond = (second) => (FORM()['time-second'].value = second);
export const getTime = () => {
  const timeHour = Number(FORM()['time-hour'].value);
  const timeMin = Number(FORM()['time-minute'].value);
  const timeSecond = Number(FORM()['time-second'].value);
  return getSeconds(timeHour, timeMin, timeSecond);
};

export const setDistanceFromEvent = () => {
  switch (FORM().event.options.selectedIndex) {
    case 1:
      setDistance(5);
      setDistanceUnit(KILOMETER);
      break;
    case 2:
      setDistance(8);
      setDistanceUnit(KILOMETER);
      break;
    case 3:
      setDistance(10);
      setDistanceUnit(KILOMETER);
      break;
    case 4:
      setDistance(HALF_MARATHON_DISTANCE);
      setDistanceUnit(MILE);
      break;
    case 5:
      setDistance(MARATHON_DISTANCE);
      setDistanceUnit(MILE);
      break;
    default:
      return null;
  }
};
