import React from 'react';

export const Feedback = () => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <div style={{ marginTop: 20 }}>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://docs.google.com/forms/d/e/1FAIpQLScgH9L2ElscVjvKLaFMJIg7B6Scr0h2EEukMfqj9L5M29nQBg/viewform?usp=sf_link"
    >
      Feedback
    </a>{' '}
    |{' '}
    <a
      target="_blank"
      href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=VGEF6VJ83QB5E&source=url"
      rel="noopener noreferrer"
    >
      Help Keep This Running
    </a>
  </div>
);
