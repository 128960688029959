import React from 'react';
import './home.css';
import Calculator from './calculator';
import { Feedback } from './feedback';

function Application() {
  return (
    <div className="main-wrapper">
      <Calculator />
      <Feedback />
    </div>
  );
}

export default Application;
