import { MILE, KILOMETER, METER, YARD } from '../constants/common';

const addLeadingZero = (value) => (value < 10 ? '0' + value : value);
export const round = (num) =>
  Math.round((num + Number.EPSILON) * 10000) / 10000;

export const getConversionFactor = (funit, tunit) => {
  if (funit === tunit) return 1;
  else if (funit === MILE && tunit === KILOMETER) return 1.609344;
  else if (funit === MILE && tunit === METER) return 1609.344;
  else if (funit === MILE && tunit === YARD) return 1760;
  else if (funit === MILE && tunit === 'Half Mile') return 2;
  else if (funit === MILE && tunit === 'Quarter Mile') return 4;
  else if (funit === MILE && tunit === 'Eigth Mile') return 8;
  else if (funit === MILE && tunit === '1500M') return 1.072896;
  else if (funit === MILE && tunit === '800M') return 2.01168;
  else if (funit === MILE && tunit === '400M') return 4.02336;
  else if (funit === MILE && tunit === '200M') return 8.04672;
  else if (funit === KILOMETER && tunit === MILE) return 0.6213712;
  else if (funit === KILOMETER && tunit === METER) return 1000;
  else if (funit === KILOMETER && tunit === YARD) return 1093.613;
  else if (funit === KILOMETER && tunit === 'Half Mile') return 1.2427424;
  else if (funit === KILOMETER && tunit === 'Quarter Mile') return 2.4854848;
  else if (funit === KILOMETER && tunit === 'Eigth Mile') return 4.9709696;
  else if (funit === KILOMETER && tunit === '1500M') return 0.66666666;
  else if (funit === KILOMETER && tunit === '800M') return 1.25;
  else if (funit === KILOMETER && tunit === '400M') return 2.5;
  else if (funit === KILOMETER && tunit === '200M') return 5;
  else if (funit === METER && tunit === MILE) return 0.0006213712;
  else if (funit === METER && tunit === KILOMETER) return 0.001;
  else if (funit === METER && tunit === YARD) return 1.093613;
  else if (funit === METER && tunit === 'Half Mile') return 0.0012427424;
  else if (funit === METER && tunit === 'Quarter Mile') return 0.0024854848;
  else if (funit === METER && tunit === 'Eigth Mile') return 0.0049709696;
  else if (funit === METER && tunit === '1500M') return 0.0006666;
  else if (funit === METER && tunit === '800M') return 0.00125;
  else if (funit === METER && tunit === '400M') return 0.0025;
  else if (funit === METER && tunit === '200M') return 0.005;
  else if (funit === YARD && tunit === MILE) return 0.0005681;
  else if (funit === YARD && tunit === KILOMETER) return 0.0009144;
  else if (funit === YARD && tunit === METER) return 0.9144;
  else if (funit === YARD && tunit === 'Half Mile') return 0.0011362;
  else if (funit === YARD && tunit === 'Quarter Mile') return 0.0022724;
  else if (funit === YARD && tunit === 'Eigth Mile') return 0.0045448;
  else if (funit === YARD && tunit === '1500M') return 0.0006096;
  else if (funit === YARD && tunit === '800M') return 0.001143;
  else if (funit === YARD && tunit === '400M') return 0.002286;
  else if (funit === YARD && tunit === '200M') return 0.004572;
  return 0;
};

export const getSeconds = (hr, min, sec) =>
  Number(hr) * 3600 + Number(min) * 60 + Number(sec);
export const getHourFromSeconds = (seconds) => Math.floor(seconds / 3600);
export const getMinuteFromSeconds = (seconds) =>
  addLeadingZero(Math.floor((seconds % 3600) / 60));
export const getRemainderSeconds = (seconds) =>
  addLeadingZero(round(seconds % 60));
