import React from 'react';
import { calculateTime, report } from './helpers';
import { logEvent } from '../utils/analytics';

export const Time = () => {
  return (
    <div className="entry-row">
      <div className="entry-unit">Time</div>
      <div className="entry-fields background-secondary">
        <div className="multiple-input-container">
          <div className="single-input">
            <label>Hours</label>
            <input
              name="time-hour"
              style={{ width: 40 }}
              type="number"
              inputMode="decimal"
            />
          </div>
          :
          <div className="single-input">
            <label>Mins</label>
            <input
              name="time-minute"
              style={{ width: 40 }}
              type="number"
              inputMode="decimal"
            />
          </div>
          :
          <div className="single-input">
            <label>Secs</label>
            <input
              name="time-second"
              style={{ width: 60 }}
              type="number"
              inputMode="decimal"
            />
          </div>
        </div>
      </div>
      <div className="entry-action background-secondary">
        <button
          type="button"
          id="button-calculate-time"
          style={{ fontSize: 12 }}
          onClick={() => {
            calculateTime();
            logEvent('CALCULATE_TIME', {
              event_category: 'PACE_CALCULATOR',
              event_label: report(),
            });
          }}
        >
          Calculate Time
        </button>
      </div>
    </div>
  );
};
