import React from 'react';
import { calculatePace, report } from './helpers';
import { logEvent } from '../utils/analytics';

export const Pace = () => (
  <div className="entry-row">
    <div className="entry-unit">Pace</div>
    <div className="entry-fields background-secondary">
      <div className="multiple-input-container">
        <div className="single-input">
          <label>Hours</label>
          <input
            name="pace-hour"
            style={{ width: 40 }}
            type="number"
            inputMode="decimal"
          />
        </div>
        :
        <div className="single-input">
          <label>Mins</label>
          <input
            name="pace-minute"
            style={{ width: 40 }}
            type="number"
            inputMode="decimal"
          />
        </div>
        :
        <div className="single-input">
          <label>Secs</label>
          <input
            name="pace-second"
            style={{ width: 60 }}
            type="number"
            inputMode="decimal"
          />
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        Per
        <select
          name="pace-unit"
          style={{ height: 28, marginLeft: 10 }}
          defaultValue="MILE"
        >
          <option value="MILE">Mile</option>
          <option value="KILOMETER">Kilometer</option>
          <option value="Half Mile">880 yrds</option>
          <option value="Quarter Mile">440 yrds</option>
          <option value="Eigth Mile">220 yrds</option>
          <option value="1500M">1500 M</option>
          <option value="800M">800 M</option>
          <option value="400M">400 M</option>
          <option value="200M">200 M</option>
          <option value="METER">Meter</option>
          <option value="YARD">Yard</option>
        </select>
      </div>
    </div>
    <div className="entry-action background-secondary">
      <button
        type="button"
        id="button-calculate-pace"
        onClick={() => {
          calculatePace();
          logEvent('CALCULATE_PACE', {
            event_category: 'PACE_CALCULATOR',
            event_label: report(),
          });
        }}
      >
        <b>Calculate Pace</b>
      </button>
    </div>
  </div>
);
