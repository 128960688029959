import React, { useEffect } from 'react';
import { Time } from './time';
import { Pace } from './pace';
import { Distance } from './distance';
// import { Predictions } from './predictions';
import { Reset } from './reset';
import { Share } from './share';
import { setParametersFromQuery } from './helpers/query';
import './calculator.css';

const Calculator = () => {
  useEffect(() => {
    setParametersFromQuery();
  }, []);
  return (
    <form id="pace-calculator" className="pace-calculator">
      <div className="pace-calculator-container">
        <h1 className="pace-calculator-header">PaceMe Pace Calculator</h1>
        <Time />
        <Distance />
        <Pace />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Reset />
        <Share />
      </div>
    </form>
  );
};

export default Calculator;
