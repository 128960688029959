import {
  getConversionFactor,
  getSeconds,
  getHourFromSeconds,
  getMinuteFromSeconds,
  getRemainderSeconds,
  round,
} from './conversion';
import {
  getDistance,
  getDistanceUnit,
  getPaceUnit,
  setTimeHour,
  setTimeMinute,
  setTimeSecond,
  getPace,
  getTime,
  setDistance,
  setPaceHour,
  setPaceMinute,
  setPaceSecond,
} from './set-get';
import { resetQuery } from './query';

export const FORM = () => document.getElementById('pace-calculator');

const isDistanceValid = () => Math.sign(getDistance()) > -1;
const isPaceValid = () => {
  const paceHour = Number(FORM()['pace-hour'].value);
  const paceMin = Number(FORM()['pace-minute'].value);
  const paceSecond = Number(FORM()['pace-second'].value);
  if (
    getSeconds(paceHour, paceMin, paceSecond) === 0 ||
    Math.sign(paceHour) < 0 ||
    Math.sign(paceMin) < 0 ||
    Math.sign(paceSecond) < 0
  ) {
    return false;
  }

  return true;
};

const isTimeValid = () => {
  const timeHour = Number(FORM()['time-hour'].value);
  const timeMin = Number(FORM()['time-minute'].value);
  const timeSecond = Number(FORM()['time-second'].value);
  if (
    getSeconds(timeHour, timeMin, timeSecond) === 0 ||
    Math.sign(timeHour) < 0 ||
    Math.sign(timeMin) < 0 ||
    Math.sign(timeSecond) < 0
  ) {
    return false;
  }

  return true;
};

export const calculateTime = () => {
  if (!isDistanceValid() || !isPaceValid()) {
    return alert('To calculate Time, enter the Pace and Distance');
  }
  const factor = getConversionFactor(getDistanceUnit(), getPaceUnit());
  const time = getDistance() * getPace() * factor;
  setTimeHour(getHourFromSeconds(time));
  setTimeMinute(getMinuteFromSeconds(time));
  setTimeSecond(getRemainderSeconds(time));
  resetQuery();
};

export const calculateDistance = () => {
  if (!isTimeValid() || !isPaceValid()) {
    return alert('To calculate Dist, enter the Time and Pace');
  }
  const factor = getConversionFactor(getDistanceUnit(), getPaceUnit());
  setDistance(round(getTime() / (getPace() * factor)));
  resetQuery();
};

export const calculatePace = () => {
  if (!isTimeValid() || !isDistanceValid()) {
    alert('To calculate Pace, enter the Time and Distance');
  }
  const factor = getConversionFactor(getDistanceUnit(), getPaceUnit());
  const paceSeconds = getTime() / getDistance() / factor;
  setPaceHour(getHourFromSeconds(paceSeconds));
  setPaceMinute(getMinuteFromSeconds(paceSeconds));
  setPaceSecond(getRemainderSeconds(paceSeconds));
  resetQuery();
};

export const report = () =>
  `${getHourFromSeconds(getTime())}:${getMinuteFromSeconds(
    getTime()
  )}:${getRemainderSeconds(
    getTime()
  )} // ${getDistance()} ${getDistanceUnit()} // ${getHourFromSeconds(
    getPace()
  )}:${getMinuteFromSeconds(getPace())}:${getRemainderSeconds(
    getPace()
  )} per ${getPaceUnit()}`;

export const reset = () => {
  FORM().reset();
  resetQuery();
};
